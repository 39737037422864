import React, { useRef, useState } from 'react'

import stepwiseimage from "../../Images/stepwiseimage.png";
import boxspeciallogo from "../../Images/boxspeciallogo.png";
import HeatmapCertificaionlanding from "../../Images/HeatmapCertificaionlanding.png";
// import Landingheader from './Landingheader';
import othercerificate from "../../Images/othercerificate.png";
import maincerificate from "../../Images/maincerificate.png";
// import ProgressBar from "@ramonak/react-progress-bar";
import WorkplaceCerificationheader from './WorkplaceCerificationheader';
import elitevideo from "../../Images/video/elitevideo.mp4";
import Addicon from "../../Images/headericon/Addicon.png";
import PlayIcon from "../../Images/headericon/PlayIcon.png";
import PauseIcon from "../../Images/headericon/PauseIcon.png";
const EliteWorkplaceCertification = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleButtonClick = () => {

        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };
    return (
        <>
            {/* <section className="topheaderland">
                <p className="rtxtpphgt">Together Possibilities are infinite</p>
            </section> */}
            <WorkplaceCerificationheader />


            <section className="landingworkplcecertficafrst">
                <div className="landingpageconatiner">
                    <div className="landingfinalflxfst">
                        <div className="landingworkplcecertrfstsctnsibk">
                            <div className="landingworkplcecertrfstsctn">
                                <h4 className="landingsctionfrstpp">Be the
                                </h4>
                                <h4 className="landingsctionfrstpp"> employer of choice</h4>
                            </div>
                            <div className="">
                                <p className="hgnrtgjkprdtcgjtxtp" style={{ color: "#fff" }}>Win the war for workforce and best workplace with
                                    superior employee experience
                                </p>
                            </div>

                            <div className="">
                                <div className="nmemldivlllj">
                                    <div className="nmemldivllljinktxt">
                                        <input type="text" placeholder="Name" />
                                    </div>
                                    <div className="nmemldivllljinhhpeml">
                                        <input type="text" placeholder="Email" />
                                    </div>

                                </div>
                                <div className="strderbtndiojhk">
                                    <button className="strderbtn">
                                        Get Started
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="elitevideosection">
                <div className="landingpageconatiner">
                    <div className="landingfinalflxfst">
                        <div className="elitevideosectiondivlrft">
                            <div className="elitevideosectiondivmain">
                                <div className="elitevideosectiondiv">
                                    <video
                                        ref={videoRef}
                                        controls={false}
                                    >
                                        <source src={elitevideo} type="video/mp4" />
                                    </video>
                                    <button onClick={handleButtonClick} className="bhbsbvidpoim">
                                        {isPlaying ?

                                            <>
                                                <div className="addiconimhj">
                                                    <img src={PauseIcon} alt="..." />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="addiconimhj">
                                                    <img src={PlayIcon} alt="..." />
                                                </div>
                                            </>

                                        }
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="elitevideosectiondivrighty">
                        <h4 className="landingsctionfrstpp" style={{ color: "#000" }}>Explore our Elite Platrom </h4>
                        <p className="landingfsrtsctntxtpp" style={{ color: "#000" }}>The Way Work and Workforce interact is going through a fundamental transformation</p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="landingworkplcecertficafscnd">

                <div className="landibwrkplcedivcltrstrfst">
                    <div className="landingpageconatiner">
                        <div className="landibwrkplcedivcltrstrfstflx">
                            <div className="landibwrkplcedivcltrstrfstwhj">
                                <h4 className="landingsctionfrstpp">solving Tomorrow’s
                                    challenges,
                                    <br></br>
                                    today!
                                </h4>
                            </div>
                            <div className="landibwrkplcedivcltrstrftfxtbtnm">
                                <p className="employrycvuujtsypp">“employee experience is the constant interplay Of — the work. the workplace, and the workforce. an employee decodes the value of their association based on the experience under these three dimensions. EXaaS helps to attract, retain, motivate and engage employees to insulate business from disruptions.”
                                </p>
                                <button className="assetghbtn">Assess where you stand, Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landibwrkplcedivcltrstrscnd">
                    <div className="landingpageconatiner">
                        <div className="landibwrkplcedivcltdivboxflx">
                            <div className="landibwrkplpercenycboxcx" style={{ padding: "12px 20px 13px 20px" }}>
                                <p className="ctsuhrrdpppnk">cost
                                    reduction
                                </p>
                                <p className="percnythvonpp">83<span>%</span></p>
                                <p className="ghtvvppyuhkk">HR leaders prioritizing
                                </p>
                            </div>
                            <div className="landibwrkplpercenycboxcx" style={{ background: "linear-gradient(125deg, rgb(237, 60, 75) 0%, rgb(255, 168, 0) 96%)" }}>
                                <p className="ctsuhrrdpppnk" style={{ color: "#fff" }}>efficiency of processes

                                </p>
                                <p className="percnythvonpp" style={{ color: "#fff" }}>82<span>%</span></p>
                                <p className="ghtvvppyuhkk" style={{ color: "#fff" }}>HR leaders prioritizing
                                </p>
                            </div>
                            <div className="landibwrkplpercenycboxcx" style={{ background: "linear-gradient(125deg, rgb(237, 60, 75) 0%, rgb(255, 168, 0) 96%)" }}>
                                <p className="ctsuhrrdpppnk" style={{ color: "#fff" }}>developing
                                    capabilities

                                </p>
                                <p className="percnythvonpp" style={{ color: "#fff" }}>70<span>%</span></p>
                                <p className="ghtvvppyuhkk" style={{ color: "#fff" }}>HR leaders prioritizing
                                </p>
                            </div>
                            <div className="landibwrkplpercenycboxcx" style={{ padding: "12px 20px 13px 20px" }}>
                                <p className="ctsuhrrdpppnk" >risk
                                    reduction

                                </p>
                                <p className="percnythvonpp">58<span>%</span></p>
                                <p className="ghtvvppyuhkk">HR leaders prioritizing
                                </p>
                            </div>
                            <div className="landibwrkplpercenycboxcx" style={{ background: "linear-gradient(125deg, rgb(237, 60, 75) 0%, rgb(255, 168, 0) 96%)" }}>
                                <p className="ctsuhrrdpppnk" style={{ color: "#fff" }}>digital agenda
                                    acceleration

                                </p>
                                <p className="percnythvonpp" style={{ color: "#fff" }}>51<span>%</span></p>
                                <p className="ghtvvppyuhkk" style={{ color: "#fff" }}>HR leaders prioritizing
                                </p>
                            </div>
                            <div className="landibwrkplpercenycboxcx">
                                <p className="ctsuhrrdpppnk">access to larger
                                    Talent pools

                                </p>
                                <p className="percnythvonpp">48<span>%</span></p>
                                <p className="ghtvvppyuhkk">HR leaders prioritizing
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="landingworkplcecertficathrd">
                <div className="landibwrkplcedivcthrdhvbfrst">
                    <div className="landingpageconatiner">
                        <div className="mangemntexpjhflxhhvhgj">
                            <div className="landibwrkplcedivcthrdhvbfrsttxtwhj">
                                <h4 className="landingsctionfrstpp">management
                                    by experience
                                </h4>
                            </div>
                            <div className="landibwrkplcedivcthrdhvbmwtxtppdiv">
                                <p className="employrycvuujtsypp" style={{ color: "#000", fontWeight: "800" }}>“the new holy grail for management is to deliver a holistic EVP and employee experience for survival and growth.”
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landibwrkplcedivcthrdhvbscnhj">
                    <div className="landingpageconatiner">
                        <div className="landibwrkplcedivcthrdhvbscnhjdivmdl">
                            <p className="employrycvuujtsypp" style={{ textAlign: "center", letterSpacing: "1px", wordSpacing: "1px" }}>“We are enabling to quantitatively measure the 7 critical pillars of a human centric EVP, identify the focus areas and then provide a roadmap for success and toolkit to create a differentiated and strong employer brand.”
                            </p>
                        </div>
                        <div className="strderbtnmainbtn">
                            <button className="strderbtn">
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="landingworkplcecertficafrth">
                <div className="landingpageconatiner">
                    <div className="landingworkplcecertficafrthflbbx">
                        <div className="landingworkplcedivfrst">
                            <h4 className="landingsctionfrstpp" style={{ color: "#000" }}>assess with our
                                research-based methodology
                            </h4>
                            <p className="tltxyupghlity" style={{ color: "#000" }}>We conduct a 360-degree assessment of client’s HR processes, policies, programs along with focused group discussions under 7 core employee experience nerve centers.
                            </p>
                            <div className="aasemntmethodolgydiv">
                                <div className="aasemntmethodolgydivflx">
                                    <div className="assmtnerthotxtppdivlistdiv">
                                        <div className="assmtnerthotxtppdiv">
                                            <p className="assmtnerthotxtpp">assessment <br></br> methodology
                                            </p>
                                        </div>
                                        <div className="">
                                            <ul className="listtypetxtppbkoul">
                                                <li className="listtypetxtppbkoli">
                                                    EVP Assessment: 3Ws (Work, Workforce Workplace), 24 Facets
                                                </li>
                                                <li className="listtypetxtppbkoli">
                                                    Data-driven Due Diligence shaping EVP

                                                </li>
                                                <li className="listtypetxtppbkoli">
                                                    Fractal Algorithm: Assigning Value to Facets

                                                </li>
                                                <li className="listtypetxtppbkoli">
                                                    Points Allocated, Heatmap score by employee  for Gap Identification
                                                </li>
                                                <li className="listtypetxtppbkoli">
                                                    Overall EVP maturity rating and recommendations
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="stepwiseimageimgmain">
                                        <div className="stepwiseimageimg">
                                            <img src={stepwiseimage} alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landingworkplcedivscngf">
                            <div className="landingworkplcedivscngfdivflx">
                                <div className="evpmaturitymodalbg">
                                    <div className="assmtnerthotxtppdiv">
                                        <p className="assmtnerthotxtpp">EVP maturity  <br></br> model ©
                                        </p>
                                    </div>
                                </div>
                                <div className="boxspeciallogoimagmain">
                                    <div className="boxspeciallogoimg">
                                        <img src={boxspeciallogo} alt="..." />
                                    </div>
                                </div>
                            </div >

                            <div div className="graphlandingioimgmain" >
                                <div className="graphlandingioimg">
                                    <img src={HeatmapCertificaionlanding} alt="..." />
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </section >

            <section className="landingworkplcecertficaffth">
                <div className="landingpageconatiner">
                    <div className="landingsxthdivmainflx">
                        <div className="landingdibfsxthfrstsectn">
                            <h4 className="landingsctionfrstpp" style={{ color: 'rgb(255, 255, 255)' }}>Elite helps you win in the marketplace </h4>
                            <p className="landingfsrtsctntxtpp" style={{ color: 'rgb(255, 255, 255)' }}>Higher Productivity and efficiency at reduced cost, reduce attrition, increasing employee moral, advocacy and loyalty. </p>
                        </div>
                        <div className="landingdibfsxthscndsectn">
                            {/* <div className="landingrfsrttgriddiv">
                                <div className=" grditmmain landinghmsxthgridfrst">
                                    <p className="elthlpsprct" style={{ color: "#000" }}>30%</p>
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "16px", paddingBottom: "3px" }}>Improved internal comm.
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridscnd" style={{ background: "linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%)" }}>
                                    <p className="elthlpsprct" style={{ color: "#000" }}>20%</p>
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "16px", paddingBottom: "3px" }}>Reduction in
                                        Attrition Rate
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridthrd">
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "19px" }}>Increased
                                        Employee
                                        Engagement
                                        and
                                        motivation
                                    </p>
                                    <p className="elthlpsprct" style={{ color: "#000", padding: "93px 0px 20px 0px" }}>
                                        30%
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridfrth" style={{ background: "linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%)" }}>
                                    <p className="elthlpsprct" style={{ color: "#000" }}>30%</p>
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "16px" }}>Reduced
                                        Hiring Cost
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridffth" style={{ flexDirection: "row", columnGap: "54px" }}>
                                    <p className="elcttxthlptxtp" style={{ textAlign: "start", color: "#000" }}>Improvement in Talent Intake
                                        Quality
                                    </p>
                                    <p className="elthlpsprct" style={{ color: "#000" }}>20%
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridsxth">
                                    <p className="elthlpsprct" style={{ color: "#000", padding: "23px 0px 60px 0px" }}>20%
                                    </p>
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "98px", paddingBottom: "11px" }}>Increase
                                        Employer
                                        Brand Recall

                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridsvnth" style={{ background: "linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%)" }}>
                                    <p className="elthlpsprct" style={{ color: "#00" }}>
                                        10%
                                    </p>
                                    <p className="elcttxthlptxtp" style={{ color: "#00", paddingTop: "16px" }}>Increased
                                        Customer
                                        Satisfaction

                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridegth" style={{ flexDirection: "row", columnGap: "32px", background: "linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%)", alignItems: "center" }}>
                                    <p className="elthlpsprct" style={{ color: "#000" }}>10%</p>
                                    <p className="elcttxthlptxtp" style={{ textAlign: "end", color: "#000" }}>Hiring at Reduced
                                        Salary (paying non-premium)
                                    </p>
                                </div>
                            </div> */}

                            <div className="landingrfsrttgriddiv">
                                <div className=" grditmmain landinghmsxthgridfrst">
                                    <p className="elthlpsprct" style={{ color: "#000" }}>30%</p>
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "16px", paddingBottom: "3px" }}>Improved internal communication
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridscnd" style={{ background: "linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%)" }}>
                                    <p className="elthlpsprct" style={{ color: "#fff" }}>20%</p>
                                    <p className="elcttxthlptxtp" style={{ color: "#fff", paddingTop: "16px", paddingBottom: "3px" }}>Reduction in
                                        Attrition Rate
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridthrd">
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "19px" }}>Increased
                                        Employee
                                        Engagement
                                        and
                                        motivation
                                    </p>
                                    <p className="elthlpsprctonnotxt" >
                                        30%
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridfrth" style={{ background: "linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%)" }}>
                                    <p className="elthlpsprct" style={{ color: "#000" }}>30%</p>
                                    <p className="elcttxthlptxtp" style={{ color: "#fff", paddingTop: "16px" }}>Reduced
                                        Hiring Cost
                                    </p>
                                </div>
                                <div className=" grditmmainonno landinghmsxthgridffth" >
                                    <p className="elcttxthlptxtp" style={{ textAlign: "start", color: "#000" }}>Improvement in Talent Intake
                                        Quality
                                    </p>
                                    <p className="elthlpsprct" style={{ color: "#000" }}>20%
                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridsxth">
                                    <p className="elthlpsprctonno" >20%
                                    </p>
                                    <p className="elcttxthlptxtpemnhop" style={{}}>Increase
                                        Employer
                                        Brand Recall

                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridsvnth" style={{ background: "linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%)" }}>
                                    <p className="elthlpsprct" style={{ color: "#000" }}>
                                        10%
                                    </p>
                                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "16px" }}>Increased
                                        Customer
                                        Satisfaction

                                    </p>
                                </div>
                                <div className=" grditmmain landinghmsxthgridegth" style={{ flexDirection: "row", columnGap: "32px", background: "linear-gradient(90deg, rgba(248, 203, 173, 1) 0%, rgba(255, 217, 103, 1) 54%)", alignItems: "center" }}>
                                    <p className="elthlpsprct" style={{ color: "#000" }}>10%</p>
                                    <p className="elcttxthlptxtp" style={{ textAlign: "end", color: "#000" }}>Hiring at Reduced
                                        Salary (paying non-premium)
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="landingworkplcecertficasxth">
                <div className='comparisoncertificate'>
                    <div className='comparisonbgbox'>
                        Comparison
                    </div>
                    <div className='tablecertificatefvn'>
                        <table style={{ width: "100%" }}>

                            <tr>
                                <th className='crtdivkttyxuhjj' style={{ textAlign: "start" }}>
                                    <p className='othrttxtpppcrty'>CRITERIA</p>
                                </th>
                                <th className='crtdivkttyxuhjjimgcerty'>
                                    <div className='othercerificategtxtppflx'>
                                        <div className='othercerificateimgmain'>
                                            <img src={othercerificate} alt="..." />
                                        </div>
                                        <div className=''>
                                            <p className='othrttxtpppcrtytxyy'>other<br></br>
                                                certifications
                                            </p>
                                        </div>
                                    </div>
                                </th>
                                <th className='crtdivkttyxuhjjimg' style={{ textAlign: "end" }}>
                                    <div className='othercerificateimgmaininhgm'>
                                        <img src={maincerificate} alt="..." />
                                    </div>
                                </th>
                            </tr>


                            <tr>

                                <td> <p className='othrttxtpppcrty'>Focus Area
                                </p>
                                </td>
                                <td><p className='othrttxtpppcrty'>HR process improvement & Cultural Audit
                                </p></td>
                                <td><p className='othrttxtpppcrty'>Employee Experience HR Process & KPI
                                </p></td>

                            </tr>
                            <tr>
                                <td>
                                    <p className='othrttxtpppcrty'>Alignment to Future of Work
                                    </p>
                                </td>
                                <td>
                                    <div class="w3-light-grey">
                                        <div class="w3-grey"></div>
                                    </div>

                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-greyscnd">
                                        <div class="w3-greyscnd"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='othrttxtpppcrty'>Alignment to Future of HR Practice

                                    </p>
                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-grey">
                                        <div class="w3-grey"></div>
                                    </div>
                                </td>
                                <td classname="tdpshvknappnwgth">

                                    <div class="w3-light-greyscnd">
                                        <div class="w3-greyscnd"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><p className='othrttxtpppcrty'>Impact on Employee Experience

                                </p></td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-grey">
                                        <div class="w3-grey"></div>
                                    </div>

                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-greyscnd">
                                        <div class="w3-greyscnd"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='othrttxtpppcrty'>Impact on Employer Branding</p>
                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-grey">
                                        <div class="w3-grey"></div>
                                    </div>

                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-greyscnd">
                                        <div class="w3-greyscnd"></div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='othrttxtpppcrty'>Customer
                                        Centricity
                                    </p>
                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-grey">
                                        <div class="w3-grey"></div>
                                    </div>

                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-greyscnd">
                                        <div class="w3-greyscnd"></div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='othrttxtpppcrty'>Impact on Revenue
                                        & Profitability
                                    </p>
                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-grey">
                                        <div class="w3-grey"></div>
                                    </div>

                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-greyscnd">
                                        <div class="w3-greyscnd"></div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='othrttxtpppcrty'>Impact on Business
                                        Resilience
                                    </p>
                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-grey">
                                        <div class="w3-grey"></div>
                                    </div>

                                </td>
                                <td classname="tdpshvknappnwgth">
                                    <div class="w3-light-greyscnd">
                                        <div class="w3-greyscnd"></div>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='othrttxtpppcrty'>Organization
                                        Size

                                    </p>
                                </td>
                                <td>
                                    <p className='othrttxtpppcrty'>650+
                                    </p>

                                </td>
                                <td>
                                    <p className='othrttxtpppcrty'>100+
                                    </p>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='othrttxtpppcrty'>Organization
                                        Size



                                    </p>
                                </td>
                                <td>
                                    <p className='othrttxtpppcrty'>12-24 Months
                                    </p>

                                </td>
                                <td>
                                    <p className='othrttxtpppcrty'>12 Months
                                    </p>

                                </td>
                            </tr>
                        </table>
                    </div>
                </div >
            </section >

            <section className="landingworkplcecertficasvnth">
                <div className="landingpageconatiner">
                    <div className="landingfinalflxfst">
                        <div className="landingfsrtsctiondiv">

                            <h4 className="landingsctionfrstpp" style={{ color: "#fff" }}>Be the employer
                                of choice

                            </h4>
                            <p className="tltxyupghlity" style={{ color: "#fff" }}>Tell us a little about your company and someone from our team will be in touch soon.

                            </p>

                        </div>
                        <div className="strtjrnydivdfrmnkklk">
                            <form>
                                <div className="manmembldnjwgj">
                                    <div className="manjekjipnpt">
                                        <input type="text" placeholder="Name" />
                                    </div>
                                    <div className="manjekjipnpt">
                                        <input type="number" placeholder="Mobile No." />
                                    </div>
                                </div>
                                <div className="manjekjipnpt">
                                    <input type="email" placeholder="Email" />
                                </div>
                                <div className="manjekjipnpt">
                                    <textarea id="" name="" rows="4" cols="4">
                                    </textarea>
                                </div>
                                <button className="bkdibfgbnmrjhk" style={{ background: "linear-gradient(125deg, rgb(237, 60, 75) 0%, rgb(255, 168, 0) 96%)" }}>
                                    Book For A Demo
                                </button>
                            </form>
                            <p className="datyrgghtxpgh" style={{ color: "#fff" }}>* Your data is safe with us. We don’t share our client’s data with anyone.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EliteWorkplaceCertification