import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import HubLandingToplogo from "../../Images/HubLandingToplogo.png"
const Eliteexperinceheader = () => {
    const navigate = useNavigate();
    const [headersticky, setHeadersticky] = useState(false)


    function setFixed() {
        if (window.scrollY > 200) {
            setHeadersticky(true)
        }
        else {
            setHeadersticky(false)

        }
    }
    window.addEventListener("scroll", setFixed)
    const sidebarlandingopen = () => {
        document.querySelector(".sidebar").style.cssText +=
            "transform:translate(0,0)";
    }
    const sidebarlandinclose = () => {
        document.querySelector(".sidebar").style.cssText +=
            "transform: translate(-350px, 0)";
    }
    return (
        <>
            <section className={headersticky ? "landingheadermainactv" : "landingheadermain"}>
                <div className="headelogomenubardivflx">
                    <div className="emplyedivlogoimg">
                        <img src={HubLandingToplogo} alt="..." />
                    </div>
                    <div className="menudivmainbdr">
                        <div className="menudivbrdr">
                            <ul className="menulnkhdrdivmn">
                                <li >
                                    <NavLink className="navglnktxtppp" to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink className="navglnktxtppp" to="/EliteExperienceHub">Elite Experience Hub
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="navglnktxtppp" to="/EliteWorkplaceCertification">Elite Workplace Certification
                                    </NavLink>
                                </li>
                                <li >
                                    <button className="navglnktxtpppbtn"
                                        type="submit"
                                        onClick={() => navigate("/login")}>log In</button>
                                </li>
                                <li >
                                    <button className="navglnktxtpppbtn"
                                        type="submit"
                                        onClick={() => navigate("/registrationpage")}>Registration</button>
                                </li>
                            </ul>
                        </div>
                        <div className='bariconlanding' onClick={sidebarlandingopen}>
                            <i className="fa-solid fa-bars"></i>
                        </div>
                    </div>
                    <div className='bariconlandingspcl' onClick={sidebarlandingopen}>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                </div>

            </section>
            <section className='sidebar' style={{ background: "linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%)" }}>
                <div className='logowthbgimgmainiconcrsflx'>
                    <div className='logowthbgimgmain'>
                        <img src={HubLandingToplogo} alt="..." />
                    </div>
                    <div className='sidebarclose' onClick={sidebarlandinclose}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className=''>
                    <ul className='sidebarlinjspclhj'>
                        <li >
                            <NavLink className="navglnktxtppp" to="/">Home</NavLink>
                        </li>
                        <li>
                            <NavLink className="navglnktxtppp" to="/EliteExperienceHub">Elite Experience Hub
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="navglnktxtppp" to="/EliteWorkplaceCertification">Elite Workplace Certification
                            </NavLink>
                        </li>
                        <li >
                            <button className="navglnktxtpppbtn"
                                type="submit"
                                onClick={() => navigate("/login")}>log In</button>
                        </li>
                        <li >
                            <button className="navglnktxtpppbtn"
                                type="submit"
                                onClick={() => navigate("/registrationpage")}>Registration</button>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Eliteexperinceheader