import React, { useEffect, useRef, useState } from "react";
import "./Landingpage.css";
// import {  useNavigate } from "react-router-dom";
// import employeelandinglogo from "../../Images/employeelandinglogo.png";
// import logo from "../../Images/Logo.png";
// import landBanBack from "../../Images/landBanBack.png";
// import landBanBack2 from "../../Images/landBanBack2.png";
// import manageInPic from "../../Images/manageInPic.png";
// import managePartBg from "../../Images/managePartBg.png";
// import headLogo from "../../Images/employeeexperiencehub.png";
// import landBanInImg from "../../Images/landBanInImg.png";
// import employEngageBackImg from "../../Images/employEngageBackImg.png";
// import compreTextImg from "../../Images/compreTextImg.png";
// import compreDesktopImg from "../../Images/compreDesktopImg.png";
// import landChartImg from "../../Images/landChartImg.png";
// import advocacyInImg from "../../Images/advocacyInImg.png";
// import networkInImg from "../../Images/networkInImg.png";
// import networkBackImg from "../../Images/networkBackImg.png";
// import onboardInImg from "../../Images/onboardInImg.png";
// import affinityInImg from "../../Images/affinityInImg.png";
// import landFootPic1 from "../../Images/landFootPic1.png";
// import landFootPic2 from "../../Images/landFootPic2.png";
// import starcmntpic from "../../Images/starcmntpic.png";
// import ftLandLogo from "../../Images/ftLandLogo.png";
// import imagelanding1 from "../../Images/imagelanding1.png";
// import imagelanding2 from "../../Images/imagelanding2.png";
// import imagelanding3new from "../../Images/imagelanding3new.png";
// import imagelanding5 from "../../Images/imagelanding5.png";
// import imagelanding6 from "../../Images/imagelanding6.png";
// import landingimage7 from "../../Images/landingimage7.png";
// import imagelanding8 from "../../Images/imagelanding8.png";
// import imagelanding9 from "../../Images/imagelanding9.png";
// import imagelanding10 from "../../Images/imagelanding10.png";
// import imagelanding11 from "../../Images/imagelanding11.png"
// import imagelanding12 from "../../Images/imagelanding12.png"
// import imagelanding13 from "../../Images/imagelanding13.png"
// import imagelanding14 from "../../Images/imagelanding14.png";
// import landingpageslider1 from "../../Images/landingpageslider1.png"
// import landingpageslider2 from "../../Images/landingpageslider2.png"
// import landingimage15 from "../../Images/landingimage15.png";
// import landingph from "../../Images/landingph.png"
// import landingeml from "../../Images/landingeml.png"
// import landinglctn from "../../Images/landinglctn.png"
// import cntnctfrm from "../../Images/cntnctfrm.png";
// import CreateNewPost from "../../Images/Icons/PNG/CreateNewPost.png"
import $ from "jquery";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import cupelite from "../../Images/cupelite.png"
// import Slider from "react-slick";

// import Logowithbackground from "../../Images/Logowithbackground.png"
import Siddd from "../../Images/Side picture Slide 2.png";
import ndpictureSlideHome from "../../Images/ndpictureSlideHome.png"
import { SemiCircleProgress } from "react-semicircle-progressbar";
import startcement from "../../Images/startcement.png";
import Frontpagehublogo from "../../Images/Frontpagehublogo.png";
import Frontpagecertificttionlogo from "../../Images/Frontpagecertificttionlogo.png";
// import employeelerning from "../../Images/employeelerning.png";
// import Desktopandphone from "../../Images/Desktopandphone.png";
// import employerreputationn from "../../Images/headericon/employerreputationn.png";
// import Mentor from "../../Images/headericon/Mentor.png";
// import culture from "../../Images/headericon/culture.png";
// import SevenEmployeeeperiencenerves from "../../Images/SevenEmployeeeperiencenerves.png";
// import bulletinboardimgekljk from "../../Images/bulletinboardimgekljk.png";
// import Openintervueimg from "../../Images/Openintervueimg.png";
// import aiimagemain from "../../Images/aiimagemain.png"
// import QuizeQuestionsmodal from "./QuizeQuestionsmodal";
// import stepwiseimage from "../../Images/stepwiseimage.png";
// import boxspeciallogo from "../../Images/boxspeciallogo.png";
// import graphlandingio from "../../Images/graphlandingio.png";
// import Security from "../../Images/Security.png";
// import securityimage from "../../Images/securityimage.png";
// import Access from "../../Images/headericon/Access.png";
// import Authentication from "../../Images/headericon/Authentication.png"
// import Securityicon from "../../Images/headericon/Securityicon.png"
import Landingheader from "./Landingheader";
import elitevideo from "../../Images/video/elitevideo.mp4";
// import Addicon from "../../Images/headericon/Addicon.png";
import PlayIcon from "../../Images/headericon/PlayIcon.png";
import PauseIcon from "../../Images/headericon/PauseIcon.png";
import QuizeQuestionsmodal from "./QuizeQuestionsmodal";
const Landingpage = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1400
  };
  // const [activelnk, setActivelnk] = useState(false);
  const [quizesection, setQuizesection] = useState(true)

  $(document).ready(function () {
    $(".client-single").on("click", function (event) {
      event.preventDefault();

      var active = $(this).hasClass("active");

      var parent = $(this).parents(".testi-wrap");

      if (!active) {
        var activeBlock = parent.find(".client-single.active");

        var currentPos = $(this).attr("data-position");

        var newPos = activeBlock.attr("data-position");

        activeBlock
          .removeClass("active")
          .removeClass(newPos)
          .addClass("inactive")
          .addClass(currentPos);
        activeBlock.attr("data-position", currentPos);

        $(this)
          .addClass("active")
          .removeClass("inactive")
          .removeClass(currentPos)
          .addClass(newPos);
        $(this).attr("data-position", newPos);
      }
    });
  });
  // const navigate = useNavigate();

  // const [stickylanding, Setstickylanding] = useState(false);


  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleButtonClick = () => {

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };


  // const [dashOffset, setDashOffset] = useState(1400);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPercentage =
  //       (window.scrollY /
  //         (document.documentElement.scrollHeight - window.innerHeight)) * 1400;

  //     console.log(`scrollPercentage: ${scrollPercentage}`);
  //     const animationSpeed = 10
  //     const newDashOffset = 1400 - scrollPercentage * animationSpeed;
  //     setDashOffset(Math.max(0, Math.min(1400, newDashOffset)));


  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [dashOffset]);

  // const [dashOffsett, setDashOffsett] = useState(1400);
  // useEffect(() => {
  //   const handleScrolll = () => {
  //     const scrollPercentagee =
  //       (window.scrollY /
  //         (document.documentElement.scrollHeight - window.innerHeight)) * 1400;

  //     console.log(`scrollPercentage: ${scrollPercentagee}`);
  //     const animationSpeedd = 10
  //     const newDashOffsett = 1400 - scrollPercentagee * animationSpeedd;
  //     if(window.scrollY>700){
  //     setDashOffsett(Math.max(0, Math.min(1400, newDashOffsett)));
  //     }
  //   };
  //   window.addEventListener("scroll", handleScrolll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScrolll);
  //   };
  // }, [dashOffsett]);





  return (
    <>

      {/* <section className="topheaderland">
        <p className="rtxtpphgt">Together Possibilities are infinite</p>
      </section> */}

      {/* <section className="header">
        <div className="custContain">
          <nav className="navbar navbar-expand-lg navbar-left landinglogo">
            <div className="logoheadertopdiv">
              <img src={employeelandinglogo} alt="..." />
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div>

              <button
                className="loginbtn"
                type="submit"
                onClick={() => navigate("/login")}
              >
                Log In
              </button>
              <button className="signupbtn" type="submit">
                Sign Up
              </button>landibimhdmmain
            </div >
          </nav >
        </div >
      </section >
      <section className="sidebarSection">
        <div className="sideHeadDiv">
          <figure className="sideHeadLogo">
            <img src={headLogo} alt="..." />
          </figure>
          <button className="sideHeadCnclBtn" onClick={() => {
            document.querySelector(".sidebarSection").style.cssText +=
              "transform: translate(-350px, 0);";
          }}>
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <ul className="sideBarUl">

          <li className="sideBarLi">
            <Link to="/" className="sideBarLiAn">
              Sign Up
            </Link>
          </li>
        </ul>
      </section>

      <section className="landingpagefirstsection">

        <div className="landingimagetxtdivfrst">
          <div className="landingfrstimagediv">
            <div className="landingimagefrstdiv">
              <img src={imagelanding1} alt="..." />
            </div>
          </div>
          <div className="lanfifhhspghdiv">
            <div className="landingfrstdivtxt">
              <div className="happytxtdiv">
                <h2 className="happytxt">a happy workplace results in greater productivity</h2>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="hepltxtdiv">
                <p className="hepltxt">we help organisations to design and deliver employee experience transformation through our product and consulting service</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landingpagesecondsection">

        <div className="landingimagetxtdivfrst">

          <div className="landingtxtdivmain">
            <div className="lajkmksnddyv">
              <div className="landingmangementtxt">
                <h2 className="mangetxtdiv">
                  'management by experience' is the secret to high performance culture
                </h2>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="lamdingemployeetxt">
                <p className="emplyetxtdiv"> Employee Experience encapsulates what people encounter and observe over the course of their tenure in the organization, it is the Moments That Matter</p>
              </div>
            </div>
          </div>
          <div className="landingscndimagediv">
            <div className="landingimagesecnddiv">
              <img src={imagelanding2} alt="..." />
            </div>
          </div>

        </div>
      </section> */}



      {/* 

      <section section className="compreHenSection" >
        <div className="containerPageLand">
          <div className="compreHenTextLeft">
            <p className="firstCompreText">
              <span className="firstCompFSpan">
                1<sup>st</sup>
              </span>
              <br /> <span className="firstCompSSpan">of its kind</span>
              <br /> comprehensive <br /> employee engagement <br /> platform
            </p>
            <figure className="compreTextFig">
              <img src={compreTextImg} alt="..." />
            </figure>
            <div className="compreAllNatioTextsDiv">
              <p className="compreNationText">National Award Winner</p>
              <p className="compreNatioSmallText">Assocham Work Vision 2022 </p>
              <p className="compreNatioSmallText">
                Annual HR Excellence Awards
              </p>
            </div>
          </div>
          <figure className="compreDeskFig">
            <img src={compreDesktopImg} alt="..." />
          </figure>
        </div>
      </section >

      <section className="labdChartImgSection">


        <div className="">
          <div className="landinghcmptimg">
            <img src={imagelanding3new} alt="..." />
            <div className="">
              <div className="pouimnabstlydiv">
                <div className="pointtxtdiv">
                  <h2 className="txtnmbr">01</h2>
                  <p className="txtspltf">employee <br /> engagement</p>
                </div>
              </div>
              <div className="pouimnabstlydiv">
                <div className="pointtxtdiv">
                  <h2 className="txtnmbr">02</h2>
                  <p className="txtspltf">employee <br /> advocacy</p>
                </div>
              </div>
              <div className="pouimnabstlydiv">
                <div className="pointtxtdiv">
                  <h2 className="txtnmbr">03</h2>
                  <p className="txtspltf">networking</p>
                </div>
              </div>
              <div className="pouimnabstlydiv">
                <div className="pointtxtdiv">
                  <h2 className="txtnmbr">04</h2>
                  <p className="txtspltf">hiring and<br />onboarding</p>
                </div>
              </div>
              <div className="pouimnabstlydiv">
                <div className="pointtxtdiv">
                  <h2 className="txtnmbr">05</h2>
                  <p className="txtspltf">employee <br /> survey</p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="pouimnabstlyrigtdiv">
                <div className="pointtxtdiv">
                  <p className="txtspltf">opportunities <br /> and initiatives</p>
                  <h2 className="txtnmbr">06</h2>
                </div>
              </div>
              <div className="pouimnabstlyrigtdiv">
                <div className="pointtxtdiv">

                  <p className="txtspltf">affinity<br /> group</p>
                  <h2 className="txtnmbr">07</h2>
                </div>
              </div>
              <div className="pouimnabstlyrigtdiv">
                <div className="pointtxtdiv">

                  <p className="txtspltf">reward and <br /> recognition</p>
                  <h2 className="txtnmbr">08</h2>
                </div>
              </div>
              <div className="pouimnabstlyrigtdiv">
                <div className="pointtxtdiv">

                  <p className="txtspltf"> top <br /> performer </p>
                  <h2 className="txtnmbr">09</h2>
                </div>
              </div>
              <div className="pouimnabstlyrigtdiv">
                <div className="pointtxtdiv">

                  <p className="txtspltf"> company <br /> bulletin board </p>
                  <h2 className="txtnmbr">10</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className="landingpagefrthsection">

        <div className="landingimagetxtdivfrst">
          <div className="landingfrstimagediv">
            <div className="landingimagefrstdiv">
              <img src={imagelanding5} alt="..." />
            </div>
          </div>
          <div className="landingfrsttxtdiv">
            <div className="landingfrstdivtxt">
              <div className="happytxtdiv">
                <h2 className="happytxtdivemp">employee engagement</h2>
              </div>
              <div className="happytxtdiv">
                <p className="wningtxt">winning in the marketplace is a consequence of winning the trust and care of your workforce,an engaged employee is the best asset that any organisation can have</p>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="hepltxtdiv">
                <p className="eltxtpp">Elite platform provides the space to plan events and engage employees for different activities and promoting them on social media through employee advocacy</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landingpagesxthsection">

        <div className="landingimagetxtdivfrst">
          <div className="landingsxthscttmndiv">
            <div className="lajkmksnddyv">
              <div className="empyeediv">
                <h2 className="advcttxt">
                  employee advocacy
                </h2>
              </div>
              <div className="divmainmsttxt">
                <p className="msttxtdiv">is the most effective marketing channel for organisations</p>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="pltfrmdivxtx">
                <p className="playttxy">Our platform provides dedicated space to boost employee advocacy across all social media channels</p>
              </div>
            </div>
          </div>
          <div className="landingsxthimgdiv">
            <div className="landingimagesecnddiv">
              <img src={imagelanding6} alt="..." />
            </div>
          </div>
        </div>
      </section>

      <section className="landingpageseventhsection">

        <div className="landingimagetxtdivfrst">
          <div className="landingfrstimagediv">
            <div className="landingimagesvnthdiv">
              <img src={landingimage7} alt="..." />
            </div>
          </div>
          <div className="networkingfrstdiv">
            <div className="landingfrstdivtxt">
              <div className="happytxtdivnet">
                <h2 className="happytxtdivemp">networking</h2>
              </div>
              <div className="poewrrctnet">
                <p className="wningtxt">the power of networking and connection is the true force multiplier</p>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="hepltxtdivnet">
                <p className="eltxtpp">Elite platform provides the space to plan events and engage employees for different activities and promoting them on social media through employee advocacy</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landingpageeighthsection">

        <div className="landingimagetxtdivfrst">
          <div className="landinbulleuib">
            <div className="landingfrstdivtxt">
              <div className="lajkmksnddyv">
                <div className="brddiv">
                  <h2 className="bordtxt">hiring experience</h2>
                </div>
                <div className="lgfrcmndiv">
                  <p className="loufcmntxt">superior hiring experience builds long term employee association and reduces attrition</p>
                </div>
                <div className="crtnewpstimg">
                  <img src={CreateNewPost} alt="..." />
                </div>
                <div className="buletdiv">
                  <p className="bulletintxt">Our platform promotes referral hiring seamless on-boarding of new employees</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bulleuntxtlandinuimg">
            <div className="imghjsgh">
              <img src={imagelanding8} alt="..." />
            </div>
          </div>
        </div>
      </section>

      <section className="landingimgnnth">

        <div className="landingimagetxtdivfrst">
          <div className="landingempltdyiv">
            <div className="landingimagesvnthdiv">
              <img src={imagelanding9} alt="..." />
            </div>
          </div>
          <div className="landingtxtemplt">
            <div className="landingfrstdivtxt">
              <div className="emplyrtxt">
                <h2 className="happytxtdivemp">employee survey</h2>
              </div>
              <div className="giijtxt">
                <p className="wningtxt"> giving employee voice ensures higher job satisfaction and reduces conflict at work</p>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="elyuyppytxt">
                <p className="eltxtpp">Our platform helps you conduct surveys, grievance suggestion box and analytics at every point in the employee life cycle </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landingimgtensctyhh">

        <div className="landingimagetxtdivfrst">
          <div className="landingsxthscttmndiv">
            <div className="lajkmksnddyv">
              <div className="empyeediv">
                <h2 className="advcttxt">
                  opportunities and initiatives
                </h2>
              </div>
              <div className="divmainmsttxt">
                <p className="msttxtdiv">give power to the employees to select activites that inspire them</p>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="pltfrmdivxtx">
                <p className="playttxy">Our platform gives avenue to drive organisation initiatives that are over and above the normal call of duty</p>
              </div>
            </div>
          </div>
          <div className="landingsxthimgdiv">
            <div className="landingimagesecnddiv">
              <img src={imagelanding10} alt="..." />
            </div>
          </div>
        </div>
      </section>

      <section className="landingimgelevntsctyhh">

        <div className="landingimagetxtdivfrst">
          <div className="lamndibdivmain">
            <div className="imghjsgh">
              <img src={imagelanding11} alt="..." />
            </div>
          </div>
          <div className="landingafntyyxy">
            <div className="landingfrstdivtxt">
              <div className="happytxtdivafnty">
                <h2 className="happytxtdivemp">affinity group</h2>
              </div>
              <div className="poewrrctdiv">
                <p className="wningtxt">building purposeful connection that are connected to a common idea and to one another - staying tribes is first nature for human beings</p>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="hepltxtdivafnty">
                <p className="eltxtpp">Our platform helps you conduct surveys, grievance suggestion box and analytics at every point in the employee life cycle </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landingimgtwselvesctyhh">

        <div className="landingimagetxtdivfrst">
          <div className="landingtwlvtxtdiv">
            <div className="lajkmksnddyv">
              <div className="empyeedivtwlxt">
                <h2 className="advcttxt">
                  reward and recognition
                </h2>
              </div>
              <div className="divmainmsttxttwl">
                <p className="msttxtdiv">Work that is rewarding and recognition that is earned from peers and leaders is valued more than and creates a high-performance culture</p>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="pltfrmdivxtxtwl">
                <p className="playttxy">Our gamification approach for increasing employee engagement inspires people to walk that extra mile</p>
              </div>
            </div>
          </div>
          <div className="landingtwlvimgdiv">
            <div className="landingimagesecnddiv">
              <img src={imagelanding12} alt="..." />
            </div>
          </div>
        </div>
      </section>

      <section className="landingimgthrteendiv">

        <div className="landingimagetxtdivfrst">
          <div className="iimagedivman">
            <div className="imghjsgh">
              <img src={imagelanding13} alt="..." />
            </div>
          </div>
          <div className="txtlanjdimfrst">
            <div className="landingfrstdivtxt">
              <div className="prmtxtdiv">
                <h2 className="perfrmtxt">top performers</h2>
              </div>
              <div className="brtytydiv">
                <p className="celbratrtxt">celebrate people that help you win and make them role models for others</p>
              </div>
              <div className="crtnewpstimg">
                <img src={CreateNewPost} alt="..." />
              </div>
              <div className="tortdiv">
                <p className="pathtxtort">Our platform makes it is easy to showcase top performers and inspire all employess to give their post </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landingimgthrfrtenndiv">
        <div className="landingimagetxtdivfrst">
          <div className="landinbulleuib">
            <div className="landingfrstdivtxt">
              <div className="lajkmksnddyv">
                <div className="brddiv">
                  <h2 className="bordtxt">Bulletin Board</h2>
                </div>
                <div className="lgfrcmndiv">
                  <p className="loufcmntxt">Communication is the lifeline of any organisation and keeping all employees on the same page is the key to success</p>
                </div>
                <div className="crtnewpstimg">
                  <img src={CreateNewPost} alt="..." />
                </div>
                <div className="buletdiv">
                  <p className="bulletintxt">Our bulletin board feature ensures all employees are aware of all important updates and changes in the organisation</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bulleuntxtlandinuimg">
            <div className="imghjsgh">
              <img src={imagelanding14} alt="..." />
            </div>
          </div>
        </div>
      </section>

      <section className="lanfdingdivlst">

        <div className="elutyriytxt">
          <div className="eliteroyflx">
            <div className="ytxtimglandinwhh">
              <h4 className="eliterroy">Why Elite-ROI</h4>
            </div>

            <div className="ytxtimglandinwhh">
              <div className="incrsemply">
                <div className="landffttnh">
                  <img src={landingimage15} alt="..." />
                </div>
                <div className="txtdivlastland">
                  <p>Increase Employer Brand Recall</p>
                </div>
              </div>
            </div>
            <div className="ytxtimglandinwhh">
              <div className="incrsemply">
                <div className="landffttnh">
                  <img src={landingimage15} alt="..." />
                </div>
                <div className="txtdivlastland">
                  <p>Reduced Hiring Cost</p>
                </div>
              </div>
            </div>
            <div className="ytxtimglandinwhh">
              <div className="incrsemply">
                <div className="landffttnh">
                  <img src={landingimage15} alt="..." />
                </div>
                <div className="txtdivlastland">
                  <p>Hiring at Reduced Salary (Payingh non-premium)</p>
                </div>
              </div>
            </div>
            <div className="ytxtimglandinwhh">
              <div className="incrsemply">
                <div className="landffttnh">
                  <img src={landingimage15} alt="..." />
                </div>
                <div className="txtdivlastland">
                  <p>Reduction in Attrition Rate</p>
                </div>
              </div>
            </div>
            <div className="ytxtimglandinwhh">
              <div className="incrsemply">
                <div className="landffttnh">
                  <img src={landingimage15} alt="..." />
                </div>
                <div className="txtdivlastland">
                  <p>Improvement in Talent Intake Qua </p>
                </div>
              </div>
            </div>
            <div className="ytxtimglandinwhh">
              <div className="incrsemply">
                <div className="landffttnh">
                  <img src={landingimage15} alt="..." />
                </div>
                <div className="txtdivlastland">
                  <p>Increased Customer Satisfaction</p>
                </div>
              </div>
            </div>
            <div className="ytxtimglandinwhh">
              <div className="incrsemply">
                <div className="landffttnh">
                  <img src={landingimage15} alt="..." />
                </div>
                <div className="txtdivlastland">
                  <p>Increased Employee Engagement and motivation</p>
                </div>
              </div>
            </div>


          </div>
          <div className="landineltmaibtstflx">
            <div className="txtallflxr">
              <h2 className="strendp">End State: Higher Productivity, efficiency at reduced cost, with continuous communication and highly motivated workforce</h2>
            </div>
            <div className="imgeltdiv">
              <div className="imgelttxtdiv">
                <img src={cupelite} alt="..." />
              </div>
              <div className="diveemk">
                <p className="emplttys">Be The Employer of Choice</p>
              </div>
            </div>
          </div>
        </div>


      </section>


      <section className="slidelandingsection">
        <div className="slidesectioncontainer">
          <Slider {...settings}>
            <div>
              <div className="landunfginf">
                <img src={landingpageslider1} alt="..." />
              </div>
            </div>
            <div>
              <div className="landunfginf">
                <img src={landingpageslider2} alt="..." />
              </div>
            </div>
            <div>
              <div className="landunfginf">
                <img src={landingpageslider1} alt="..." />
              </div>
            </div>
            <div>
              <div className="landunfginf">
                <img src={landingpageslider2} alt="..." />
              </div>
            </div>
          </Slider>
        </div>
      </section>

      <section className="">
        <div className="slidesectioncontainer">
          <div className="frmldnnflx">
            <div className="bglanffrm" style={{ backgroundImage: `url(${cntnctfrm})` }}>
              <div className="cnthjdiv">
                <p className="ttxybjkk">Contact Information</p>
                <p className="filklltxyt">Fill Up the Form and Our Team will get back to You within 24 Hours</p>
                <div className="">
                  <span className="lsjkk">
                    <img src={landingph} alt="..."></img>
                    <p className="fltuupp">+91-9830783606</p>
                  </span>
                  <span className="lsjkk">
                    <img src={landingeml} alt="..."></img>
                    <p className="fltuupp">hello@fractalsglobal.com</p>
                  </span>
                  <span className="lsjkk">
                    <img src={landinglctn} alt="..."></img>
                    <p className="fltuupp">Kolkata, India</p>
                  </span>
                </div>
                <div className="sbnhj">
                  <div className="jjatxtyt">
                    <i className="fa-brands fa-facebook-f"></i>
                  </div>
                  <div className="jjatxtyt">
                    <i className="fa-brands fa-twitter"></i>
                  </div>
                  <div className="jjatxtyt">
                    <i className="fa-brands fa-instagram"></i>
                  </div>
                  <div className="jjatxtyt">
                    <i className="fa-brands fa-linkedin-in"></i>
                  </div>

                </div>
              </div>
            </div>
            <div className="fhhdiv">
              <form>
                <div className="nametotal">
                  <div className="fdrtydiv">
                    <label for="fname">First name</label>
                    <input type="text" id="" name="" />
                  </div>
                  <div className="fdrtydiv">
                    <label for="lname">Last Name</label>
                    <input type="text" id="" name="" />
                  </div>
                </div>
                <div className="nametotal">
                  <div className="fdrtydiv">
                    <label for="mail">Mail</label>
                    <input type="text" id="" name="" />
                  </div>
                  <div className="fdrtydiv">
                    <label for="phone">Phone</label>
                    <input type="text" id="" name="" />
                  </div>
                </div>

                <div className="sfllmdbj">
                  <div className="jkjkda">
                    <label for="fname">Message</label>
                    <input type="text" id="" name="" placeholder="Write Your Message" />
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </section>

      <footer className="footerLanding">

        <div className="secondFooterPart">
          <figure className="sndFtPrtFig">
            <img src={landBanBack2} alt="..." />
          </figure>
          <div className="secondInnerFtDiv">
            <div className="containerPageLand">
              <div className="footerLandInDiv">
                <div>
                  <figure className="ftLandFig">
                    <img src={ftLandLogo} alt="..." />
                  </figure>
                  <div>
                    <p className="allFootLandHead">Follow</p>
                    <ul className="footerLfticonUl">
                      <li className="footerLfticonLi">
                        <Link to="/" className="footLeftInIconsAn">
                          <i class="fa-brands fa-linkedin"></i>
                        </Link>
                      </li>
                      <li className="footerLfticonLi">
                        <Link to="/" className="footLeftInIconsAn">
                          <i class="fa-brands fa-youtube"></i>
                        </Link>
                      </li>
                      <li className="footerLfticonLi">
                        <Link to="/" className="footLeftInIconsAn">
                          <i class="fa-brands fa-facebook"></i>
                        </Link>
                      </li>
                      <li className="footerLfticonLi">
                        <Link to="/" className="footLeftInIconsAn">
                          <i class="fa-brands fa-square-twitter"></i>
                        </Link>
                      </li>
                      <li className="footerLfticonLi">
                        <Link to="/" className="footLeftInIconsAn">
                          <i class="fa-brands fa-square-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <p className="allFootLandHead">resources</p>
                  <ul className="footLndOtherUl">
                    <li className="footLndOtherLi">
                      <Link to="/" className="footLndOtherLiAn">
                        Podcasts
                      </Link>
                    </li>
                    <li className="footLndOtherLi">
                      <Link to="/" className="footLndOtherLiAn">
                        Insights
                      </Link>
                    </li>
                    <li className="footLndOtherLi">
                      <Link to="/" className="footLndOtherLiAn">
                        Blog
                      </Link>
                    </li>
                    <li className="footLndOtherLi">
                      <Link to="/" className="footLndOtherLiAn">
                        News
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="allFootLandHead">about</p>
                  <ul className="footLndOtherUl">
                    <li className="footLndOtherLi">
                      <Link to="/" className="footLndOtherLiAn">
                        Our Mission
                      </Link>
                    </li>
                    <li className="footLndOtherLi">
                      <Link to="/" className="footLndOtherLiAn">
                        Impact
                      </Link>
                    </li>
                    <li className="footLndOtherLi">
                      <Link to="/" className="footLndOtherLiAn">
                        Team
                      </Link>
                    </li>
                    <li className="footLndOtherLi">
                      <Link to="/" className="footLndOtherLiAn">
                        Press Room
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="allFootLandHead">contact</p>
                  <ul className="footLndOtherUl">
                    <li className="footLndOtherLi">
                      <Link to="/" className="footLndOtherLiAn">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="containerPageLand">
                  <ul className="ftrUl">
                    <li className="ftrLi">
                      <Link to="/" className="ftrLiAn">
                        Help Desk
                      </Link>
                    </li>
                    <li className="ftrLi">
                      <Link to="/" className="ftrLiAn">
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="ftrLi">
                      <Link to="/" className="ftrLiAn">
                        Terms of Use
                      </Link>
                    </li>
                    <li className="ftrLi">
                      <Link to="/" className="ftrLiAn">
                        Community Guidelines
                      </Link>
                    </li>
                    <li className="ftrLi">
                      <Link to="/" className="ftrLiAn">
                        FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}

      <Landingheader />
      {/* Home Section Start */}




      <section className="landingfistmainfinal">
        <div className="landingpageconatiner">
          <div className="landingfinalflxfst">
            <div className="landingfsrtsctiondiv">
              <h4 className="landingsctionfrstpp">a happy workplace results in greater Productivity </h4>
              <p className="landingfsrtsctntxtpp">we help organizations to transform employee experience
                through our product and consulting services. </p>
              <div className="">
                <div className="nameemldivflx">
                  <div className="namedibsbjk">
                    <input type="text" placeholder="Name" />
                  </div>
                  <div className="namedibsbjk">
                    <input type="text" placeholder="Email" />
                  </div>
                </div>
                <button className="demobtn">Book For A Demo</button>
              </div>
            </div>
            <div className="landingscndsctiondiv">
              <div className="landibimhdmmain">
                <img src={Siddd} alt="..." />
              </div>


            </div>
          </div>
        </div>
        {/* <div className="manageFigDiv">
          <div
            data-is-ix2-target={1}
            className="lottie-animation-vh-scroll waveanimunique-billion-001"
            data-w-id="1de80802-7247-78fc-1553-15db946b65b8"
            data-animation-type="lottie"
            data-src="https://assets-global.website-files.com/60f7fb81f020d41a00e2fd84/60f7fb81f020d43184e2fdb2_wave-square-billion-r01c03b-4s.json"
            data-loop={0}
            data-direction={1}
            data-autoplay={0}
            data-renderer="svg"
            data-default-duration="4.004003840917587"
            data-duration="6.004003840917587"
          >
            <svg
              xmlns="http://www.w3.org/1400/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 1000 1000"
              width={1000}
              height={1134}
              preserveAspectRatio="xMidYMid meet"
              style={{
                width: "100%",
                height: "auto",
                transform: "translate3d(0px, 0px, 0px)",
                contentVisibility: "visible",
                opacity: "1",
              }}
            >
              <defs>
                <clipPath id="__lottie_element_68">
                  <rect width={1000} height={1000} x={0} y={0} />
                </clipPath>
              </defs>
              <g clipPath="url(#__lottie_element_68)">
                <g
                  transform="matrix(1,0,0,1,0,0,1,0)"
                  opacity={1}
                  style={{ display: "block" }}
                >
                  <g
                    opacity={1}
                    transform="matrix(1,0,0,1,597.2670288085938,479.8370056152344)"
                  >
                    <path
                      className="animated-path"
                      style={{
                        strokeDasharray: 1400,
                        strokeDashoffset: dashOffset,
                      }}
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity={0}
                      strokeMiterlimit={10}
                      stroke="#ffffff"
                      strokeOpacity={1}
                      strokeWidth={2}
                      d=" M-272.47900390625,-523.93798828125 C-232.42100524902344,-358.6579895019531 -106.0510025024414,-315.2040100097656 -65.20999908447266,-249.49000549316406 C33.959999084472656,-90.04100036621094 -95.84300231933594,-48.17599868774414 -130.01300048828125,80.9520034790039 C-218.8699951171875,416.8240051269531 257.20098876953125,523.9390258789062 272.47900390625,522.7999877929688"
                    />
                  </g>
                </g>

                <g
                  transform="matrix(1,0,0,1,0,0,1,0)"
                  opacity={1}
                  style={{ display: "block" }}
                >
                  <g
                    opacity={1}
                    transform="matrix(1,0,0,1,588.4719848632812,491.9410095214844)"
                  >
                    <path
                      className="animated-path"
                      style={{
                        strokeDasharray: 1400,

                        strokeDashoffset: dashOffset,
                      }}
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity={0}
                      strokeMiterlimit={10}
                      stroke="#ffffff"
                      strokeOpacity={1}
                      strokeWidth={2}
                      d=" M-263.6820068359375,-533.5360107421875 C-226.08140073242188,-353.7040100097656 -97.25599670410156,-323.3190002441406 -53.80699920654297,-254.52099609375 C45.35100173950195,-97.12200164794922 -80.8239974975586,-55.926998138427734 -115.73200225830078,82.25199890136719 C-198.341400720214844,409.5929870605469 238.25999450683594,526.1539916992188 263.6820068359375,533.5360107421875"
                    />
                  </g>
                </g>

                <g
                  transform="matrix(1,0,0,1,0,0,1,0)"
                  opacity={1}
                  style={{ display: "block" }}
                >
                  <g
                    opacity={1}
                    transform="matrix(1,0,0,1,579.7650146484375,504.7669982910156)"
                  >
                    <path
                      className="animated-path"
                      style={{
                        strokeDasharray: 1400,

                        strokeDashoffset: dashOffset,
                      }}
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity={0}
                      strokeMiterlimit={10}
                      stroke="#ffffff"
                      strokeOpacity={1}
                      strokeWidth={2}
                      d=" M-254.94700622558594,-543.7459716796875 C-219.7030029296875,-349.3840026855469 -88.4219970703125,-332.0690002441406 -42.491400579833984,-260.27398681640625 C56.55099868774414,-104.9014004272461 -65.89099884033203,-64.4000015258789 -101.41100311279297,82.91600036621094 C-178.29200744628906,401.31400048828125 219.12140059814453,527.6699829101562 254.947998046875,543.7459716796875"
                    />
                  </g>
                </g>

                <g
                  transform="matrix(1,0,0,1,0,0,1,0)"
                  opacity={1}
                  style={{ display: "block" }}
                >
                  <g
                    opacity={1}
                    transform="matrix(1,0,0,1,570.9190063476562,517.4520263671875)"
                  >
                    <path
                      className="animated-path"
                      style={{
                        strokeDasharray: 1400,

                        strokeDashoffset: dashOffset,
                      }}
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity={0}
                      strokeMiterlimit={10}
                      stroke="#ffffff"
                      strokeOpacity={1}
                      strokeWidth={2}
                      d=" M-246.20199584960938,-553.9010009765625 C-213.31399536132812,-345.0090026855469 -79.57499694824219,-340.7650146484375 -31.061400274658203,-265.9949951171875 C67.97000122070312,-112.6719970703125 -51.051998138427734,-72.7959976196289 -87.014000183105469,83.63400268554688 C-158.32899475097656,393.2430114746094 200.23699951171875,529.3040161132812 246.2010040283203,553.9010009765625"
                    />
                  </g>
                </g>

                <g
                  transform="matrix(1,0,0,1,0,0,1,0)"
                  opacity={1}
                  style={{ display: "block" }}
                >
                  <g
                    opacity={1}
                    transform="matrix(1,0,0,1,562.18798828125,530.1690063476562)"
                  >
                    <path
                      className="animated-path"
                      style={{
                        strokeDasharray: 1400,

                        strokeDashoffset: dashOffset,
                      }}
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity={0}
                      strokeMiterlimit={10}
                      stroke="#ffffff"
                      strokeOpacity={1}
                      strokeWidth={2}
                      d=" M-237.4669952392578,-564.1110229492188 C-206.93499755859375,-340.69000244140625 -70.81900024414062,-349.38299560546875 -19.721400122070312,-271.631400048828125 C79.19400024414062,-120.34200286865234 -36.32140076293945,-81.2249984741211 -72.75900268554688,84.2979965209961 C-138.81199645996094,385.0320129394531 181.1300048828125,530.9290161132812 237.4669952392578,564.1110229492188"
                    />
                  </g>
                </g>

                <g
                  transform="matrix(1,0,0,1,0,0,1,0)"
                  opacity={1}
                  style={{ display: "block" }}
                >
                  <g
                    opacity={1}
                    transform="matrix(1,0,0,1,553.4169921875,542.9520263671875)"
                  >
                    <path
                      className="animated-path"
                      style={{
                        strokeDasharray: 1400,

                        strokeDashoffset: dashOffset,
                      }}
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity={0}
                      strokeMiterlimit={10}
                      stroke="#ffffff"
                      strokeOpacity={1}
                      strokeWidth={2}
                      d=" M-228.66900634765625,-574.2780151367188 C-200.6199951171875,-336.41400146484375 -62.04899978637695,-358.1759948730469 -8.366999626159668,-277.4570007324219 C90.53700256347656,-128.2100067138672 -21.812999725341797,-89.89099884033203 -58.39899826049805,84.89700317382812 C-119.58899688720703,376.7139892578125 162.14100646972656,532.3569946289062 228.66900634765625,574.2780151367188"
                    />
                  </g>
                </g>

                <g
                  transform="matrix(1,0,0,1,0,0,1,0)"
                  opacity={1}
                  style={{ display: "block" }}
                >
                  <g
                    opacity={1}
                    transform="matrix(1,0,0,1,544.6339721679688,555.6799926757812)"
                  >
                    <path
                      className="animated-path"
                      style={{
                        strokeDasharray: 1400,
                        strokeDashoffset: dashOffset,
                      }}
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      fillOpacity={0}
                      strokeMiterlimit={10}
                      stroke="#ffffff"
                      strokeOpacity={1}
                      strokeWidth={2}
                      d=" M-219.88400268554688,-584.4990234375 C-194.29299926757812,-332.0814007324219 -53.241400579833984,-366.8059997558594 3.0230000019073486,-283.11199951171875 C101.91600036621094,-135.91400146484375 -7.4714001220703125,-98.35099792480469 -44.10599899291992,85.68199920654297 C-100.37699890136719,368.3420104980469 143.08399963378906,533.9710083007812 219.88299560546875,584.4990234375"
                    />
                  </g>
                </g>



              </g>
            </svg>
          </div>
        </div> */}
      </section >

      <section className="elitevideosection">
        <div className="landingpageconatiner">
          <div className="landingfinalflxfst">
            <div className="elitevideosectiondivlrft">
              <div className="elitevideosectiondivmain">
                <div className="elitevideosectiondiv">
                  <video
                    ref={videoRef}
                    controls={false}
                  >
                    <source src={elitevideo} type="video/mp4" />
                  </video>
                  <button onClick={handleButtonClick} className="bhbsbvidpoim">
                    {isPlaying ?

                      <>
                        <div className="addiconimhj">
                          <img src={PauseIcon} alt="..." />
                        </div>
                      </>
                      :
                      <>
                        <div className="addiconimhj">
                          <img src={PlayIcon} alt="..." />
                        </div>
                      </>

                    }
                  </button>
                </div>
              </div>

            </div>

            <div className="elitevideosectiondivrighty">
              <h4 className="landingsctionfrstpp" style={{ color: "#000" }}>Explore our Elite Platrom </h4>
              <p className="landingfsrtsctntxtpp" style={{ color: "#000" }}>The Way Work and Workforce interact is going through a fundamental transformation</p>
            </div>

          </div>
        </div>

      </section>

      <sectiion className="landingscndmainfinal">
        <div className="landingpageconatiner">
          <div className="landingfinalflxfst">



            <div className="landingfsrtsctiondiv">
              <h4 className="landingsctionfrstpp" style={{ color: "#000" }}><span className="">management by experience </span>is the secret to high performance culture  </h4>
              <p className="landingfsrtscndtxtppgb">Employee experience encapsulates what people encounter and observe over the course of their tenure in the organization, it is the <span> Moments That Matter </span> </p>
            </div>





            <div className="landingscndsctiondiv">
              <div className="landibimhdmmain">
                <img src={ndpictureSlideHome} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </sectiion>

      <section className="landingthrddmainfinal">
        <div className="landingpageconatiner">
          <div className="landingthrddivflx">
            <div className="landingthrddivwhhh">
              <h4 className="landingsctionfrstpp" style={{ color: "#000" }}>
                employee
                experience
                is critical to
                business
                continuity
              </h4>
            </div>
            <div className="marketvoicedivehh">
              <div className="mrktvcdib">
                market Voice
              </div>
              <div className="mrktvcdivflx">
                <div className="mrktvcdivwhfgdfdiv">
                  <div className="mrktvcdivoenghdjh">
                    <SemiCircleProgress
                      percentage={10}

                      size={{
                        width: 200,
                        height: 180,
                      }}
                      strokeWidth={10}
                      strokeColor="#ffa81b"
                      bgStrokeColor="#c9e6fc"
                      hasBackground={true}
                    />
                    <p className="mrktcvdtstcp">A negative reputation can cost a company as much as 10% more per hire
                    </p>
                  </div>

                </div>
                <div className="mrktvcdivwhfgdfdiv">
                  <div className="mrktvcdivoenghdjh">
                    <SemiCircleProgress
                      percentage={28}
                      size={{
                        width: 200,
                        height: 180,
                      }}
                      strokeWidth={10}
                      strokeColor="#ffa81b"
                      bgStrokeColor="#c9e6fc"
                      hasBackground={true}
                    />
                    <p className="mrktcvdtstcp">Employee turnover can reduced by 28% by in employer branding

                    </p>
                  </div>

                </div>
                <div className="mrktvcdivwhfgdfdiv">
                  <div className="mrktvcdivoenghdjh">
                    <SemiCircleProgress
                      percentage={59}
                      size={{
                        width: 200,
                        height: 180,
                      }}
                      strokeWidth={10}
                      strokeColor="#ffa81b"
                      bgStrokeColor="#c9e6fc"
                      hasBackground={true}
                    />
                    <p className="mrktcvdtstcp">59% of employers see employer branding as key to their overall HR strategy
                    </p>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div className="marketvoicedidvtwodivmain">
            <div className="marketdoibvbgyklstbgk">
              <div className="">
                <p className="marketdivfrsttxtppp">The very definition of an organisation is being challenged. employees are disconnected, isolated and fragmented. current situation is increasing stress levels for employees resulting in high attrition, low engagement and low productivity.
                </p>
              </div>
            </div>
            <div className="marketdivwscvjnkkl">
              <div className="">
                <p className="marketdivfrsttxtppp">The way Work and Workforce interacts is going through a fundamental transformation. The pandemic has accelerated the movement towards a digital workplace, and it has also highlighted gaps in toolsets at workplace and siloed mind-sets of workforce.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section >

      <section className="landingfrthdmainfinal">
        <div className="secondsdivcntinflandib">
          <div className="landingfrthdmainfinalbg">
            <div className="">
              <h4 className="landingsctionfrstpp" style={{ textAlign: "center", color: "#000" }}>today’s  organisation needs strong  employer brand value to attract and retain top talents </h4>
            </div>
            <div className="emplyeexprectunftewrkflx">
              <div className="emplotexpertblubrdrdiv">
                <p className="emplyetxtppdivppp">employee
                  experience
                </p>
                <p className="prcghjjdthvxpp">47<span>%</span></p>
                <p className="hrldrtuppxgj">HR leaders prioritizing</p>

              </div>
              <div className="emplotexpertblubrdrdiv">
                <p className="emplyetxtppdivppp">recruiting</p>
                <p className="prcghjjdthvxpp">46<span>%</span></p>
                <p className="hrldrtuppxgj">HR leaders prioritizing
                </p>
              </div>
              <div className="emplotexpertblubrdrdiv">
                <p className="emplyetxtppdivppp">future of
                  work
                </p>
                <p className="prcghjjdthvxpp">52<span>%</span></p>
                <p className="hrldrtuppxgj">HR leaders prioritizing
                </p>
              </div>

            </div>
            <div className="">
              <h4 className="chrtptytxtpp">CHRO’s priorities
              </h4>
            </div>
          </div>

        </div>
      </section>

      <section className="landingfifthdmainfimnal">
        <div className="secondsdivcntinflandib">
          <div className="landingfifthdmainfinalbg">
            <div className="">
              <h4 className="landingsctionfrstpp" style={{ textAlign: "center", color: "#ffa810" }}>Take the leap

              </h4>
              <h4 className="landingthrddivpppttpspan">
                to scale your business with superior employee Experince
              </h4>
            </div>
            <div className="ecxpericelanfibhlodoftxtyflx">
              <div className="ecxpericelanfibhlododivwhtf">
                <div className="logopmainpagedibjhk">
                  <img src={Frontpagehublogo} alt="..." />
                </div>
              </div>
              <div className="ecxpericelanfibhlododivtxtppdh">
                <div className="">
                  <p className="experibvdttxpbj">A comprehensive reward point based platform to gamify and increase employees engagement, reduce attrition and improve productivity</p>
                  <button className="startddcntbudiv">
                    Get started
                  </button>
                </div>
              </div>
            </div>
            <div className="ecxpericelanfibhlodoftxtyflx">
              <div className="ecxpericelanfibhlododivwhtf">
                <div className="logopmainpagedibjhk">
                  <img src={Frontpagecertificttionlogo} alt="..." />
                </div>
              </div>
              <div className="ecxpericelanfibhlododivtxtppdh">
                <div className="">
                  <p className="experibvdttxpbj">Elite workplace certification is a 360-degree assessment of client's HR processes, policies, programs using data, FGD, and survey under seven  core Employee Experience nerve centers. </p>
                  <button className="startddcntbudiv">
                    Get started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landingsxthdmainfinal">
        <div className="secondsdivcntinflandib">
          <div className="landingsxthhdmainfinalbg">
            <div className="landingsxthdivmainflx">
              <div className="landingdibfsxthfrstsectn">
                <h4 className="landingsctionfrstpp">Elite helps you win
                  in the marketplace </h4>
                <p className="landingfsrtsctntxtpp">Higher Productivity and efficiency at reduced cost, reduce attrition, increasing employee moral, advocacy and loyalty. </p>
              </div>

              <div className="landingdibfsxthscndsectn">
                <div className="landingrfsrttgriddiv">
                  <div className=" grditmmain landinghmsxthgridfrst">
                    <p className="elthlpsprct" style={{ color: "#000" }}>30%</p>
                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "16px", paddingBottom: "3px" }}>Improved internal communication
                    </p>
                  </div>
                  <div className=" grditmmain landinghmsxthgridscnd" style={{ background: "linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%)" }}>
                    <p className="elthlpsprct" style={{ color: "#fff" }}>20%</p>
                    <p className="elcttxthlptxtp" style={{ color: "#fff", paddingTop: "16px", paddingBottom: "3px" }}>Reduction in
                      Attrition Rate
                    </p>
                  </div>
                  <div className=" grditmmain landinghmsxthgridthrd">
                    <p className="elcttxthlptxtp" style={{ color: "#000", paddingTop: "19px" }}>Increased
                      Employee
                      Engagement
                      and
                      motivation
                    </p>
                    <p className="elthlpsprctonnotxt" >
                      30%
                    </p>
                  </div>
                  <div className=" grditmmain landinghmsxthgridfrth" style={{ background: "linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%)" }}>
                    <p className="elthlpsprct" style={{ color: "#fff" }}>30%</p>
                    <p className="elcttxthlptxtp" style={{ color: "#fff", paddingTop: "16px" }}>Reduced
                      Hiring Cost
                    </p>
                  </div>
                  <div className=" grditmmainonno landinghmsxthgridffth" >
                    <p className="elcttxthlptxtp" style={{ textAlign: "start", color: "#000" }}>Improvement in Talent Intake
                      Quality
                    </p>
                    <p className="elthlpsprct" style={{ color: "#000" }}>20%
                    </p>
                  </div>
                  <div className=" grditmmain landinghmsxthgridsxth">
                    <p className="elthlpsprctonno" >20%
                    </p>
                    <p className="elcttxthlptxtpemnhop" style={{}}>Increase
                      Employer
                      Brand Recall

                    </p>
                  </div>
                  <div className=" grditmmain landinghmsxthgridsvnth" style={{ background: "linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%)" }}>
                    <p className="elthlpsprct" style={{ color: "#fff" }}>
                      10%
                    </p>
                    <p className="elcttxthlptxtp" style={{ color: "#fff", paddingTop: "16px" }}>Increased
                      Customer
                      Satisfaction

                    </p>
                  </div>
                  <div className=" grditmmain landinghmsxthgridegth" style={{ flexDirection: "row", columnGap: "32px", background: "linear-gradient(90deg, rgba(0, 50, 115, 1) 0%, rgba(0, 76, 147, 1) 54%, rgba(0, 112, 191, 1) 100%)", alignItems: "center" }}>
                    <p className="elthlpsprct" style={{ color: "#fff" }}>10%</p>
                    <p className="elcttxthlptxtp" style={{ textAlign: "end", color: "#fff" }}>Hiring at Reduced
                      Salary (paying non-premium)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landingsevnthhdmainfimnal">
        <div className="secondsdivcntinflandib">
          <div className="landingsixthdmainfinalbg">
            <div className="landingsixthdivmainflx">
              <div className="landingsixtghjtxtyimdivgg">
                <p className="frctlsypptxtjnj">“Fractals is one of those unique companies that stand-out in the HR Consulting space who seamlessly blends Technology and HR process to deliver 360-degree solutions around Employee Experience. Their strong foundation of Industry knowledge, approach of co-creating solutions, and their analytics framework for data backed intervention models is of very high quality. I wish them great success!”
                </p>
                <div className="smrtctpppbh">
                  <p className="smrtctpppbhopyyctt">Samar Banerjee
                  </p>
                  <h4 className="chrthbopjioxty">CHRO,
                  </h4>
                  <h4 className="chrthbopjioxty">
                    STAR CEMENT
                  </h4>
                </div>
              </div>
              <div className="landingssixthleftdivimgui">
                <div className="startcementimghjj">
                  <img src={startcement} alt="..." />
                </div>
              </div>
            </div>
          </div>
        </div>

      </section >

      <section className="landingeighthhdmainfimnal">
        <div className="secondsdivcntinflandib">
          <div className="landingffthdmainfinalbg">
            <div className="stryrfvjrtfrmdivflsx">
              <div className="strtjrnydivbgkphwh">
                <div className="">
                  <h4 className="landingsctionfrstpp">Let’s start
                    our journey </h4>
                  <p className="tltxyupghlity">Tell us a little about your company and someone from our team will be in touch soon</p>
                </div>
              </div>
              <div className="strtjrnydivdfrmnkklk">
                <form>
                  <div className="manmembldnjwgj">
                    <div className="manjekjipnpt">
                      <input type="text" placeholder="Name" />
                    </div>
                    <div className="manjekjipnpt">
                      <input type="number" placeholder="Mobile No." />
                    </div>
                  </div>
                  <div className="manjekjipnpt">
                    <input type="email" placeholder="Email" />
                  </div>
                  <div className="manjekjipnpt">
                    <textarea id="" name="" rows="4" cols="4">
                    </textarea>
                  </div>
                  <button className="bkdibfgbnmrjhk">
                    Book For A Demo
                  </button>
                </form>
                <p className="datyrgghtxpgh">* Your data is safe with us. We don’t share our client’s data with anyone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>






      {/* Home Section End */}

      {/* Elite Experience Hub Section Star */}

      {/* Elite Experience Hub Section End */}

      {/* Elite WorkPalce Certification Start */}


      {/* Elite WorkPalce Certification End */}

      {quizesection && <QuizeQuestionsmodal closemodal={setQuizesection} />}

    </>
  );
};

export default Landingpage;
