import { combineReducers, configureStore  } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
// import thunk from "redux-thunk";

import jobRecruitmentSlice from "./slices/jobRecruitmentSlice";
import registrationSlice from "./slices/registrationSlice";

let rootReducer = combineReducers({
  registrationSlice:registrationSlice,
  jobRecruitmentSlice: jobRecruitmentSlice,
})

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== "production",
//   middleware: [thunk],
// });


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  // middleware,
});

export const persistor = persistStore(store);

export default store;