import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../constants/config";
import toast from "react-hot-toast";

const initialState = {
  isLoading: false,
  isRegistered: false,
  error: null,
};

export const registerCompany = createAsyncThunk(
  'registration/registerCompany',
  async (requestData) => {
    // console.log("hjkbm",requestData);
    // return;
    try {
      const response = await fetch(`${BASE_URL}/api/v1/admin/company-register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON?.stringify(requestData),
  
      });
      if (!response.status) {
        const errorData = await response?.json();
        throw new Error(errorData?.message || 'Failed to register company');
      }
      toast?.success("Register successfully")
      return await response?.json();
      
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice for registration
const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    clearRegistration: (state) => {
      state.isLoading = false;
      state.isRegistered = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerCompany.pending, (state) => {
        state.isLoading = true;
        state.isRegistered = false;
        state.error = null;
      })
      .addCase(registerCompany.fulfilled, (state) => {
        state.isLoading = false;
        state.isRegistered = true;
        state.error = null;
      })
      .addCase(registerCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isRegistered = false;
        state.error = action?.error?.message || 'Failed to register company';
      });
  },
});


export const { clearRegistration } = registrationSlice.actions;
export default registrationSlice.reducer;
