import React, { useEffect, useState } from 'react';
import employeelandinglogo from "../../Images/employeelandinglogo.png"
// import { useDispatch } from 'react-redux';
// import { getLoginSurvey } from '../../redux/slices/jobRecruitmentSlice';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';
import { ApiHelperFunction } from '../../services/api/apiHelpers';
import toast from 'react-hot-toast';

// const animatedComponents = makeAnimated();

const QuizeQuestionsmodal = ({ closemodal }) => {
    // const loginSurvey = useSelector((state) => state?.jobRecruitmentSlice?.loginSurvey)
    // const dispatch = useDispatch();
    // console.log("loginSurvey", loginSurvey)
    // const [DepartmentName, setDepartmentName] = useState("");
    const [DepartmentDropdown, setDepartmentDropdown] = useState([]);
    const [options, setOptions] = useState([]);
    const [userEmail, setuserEmail] = useState("");
    const [answerGiven, setanswerGiven] = useState("");
    const [per, setPer] = useState(false);
    const [surveyData, setSurveyData] = useState([]);
    const [surveyans, setsurveyans] = useState({});
    // const [questioncolors, setQuestioncolors] = useState(true);

    const handleChangeAns = (questionId, value) => {
        setsurveyans(prevState => ({
            ...prevState,
            [questionId]: value
        }));
    };


    // const handlequwstionclick = (val) => {
    //     setanswerGiven(val);
    //     setPer(!per);
    // }

    //onChange functionality for questions
    // const handleSelectDepartName = selectedOption => {
    //     setDepartmentName(selectedOption ? selectedOption.value : "");
    // }

    //get all questions
    const getLoginsurveyData = async () => {
        const res = await ApiHelperFunction({
            urlPath: "/get-login-survey",
            method: "GET"
        });
        if (res && res?.status) {
            setSurveyData(res?.data?.data?.[0]?.data)
        }
    }

    // console.log("optionsfghh",surveyData)


    //get question-wise answers 
    const getManagerData = async () => {
        // setIsLoading(true);
        const res = await ApiHelperFunction({
            urlPath: `/get-login-survey-answise/${DepartmentDropdown?.[0]?.value}`,
            method: "POST"
        });
        // console.log("resGetCatman", res)
        let apiData = []
        if (res && res?.status) {
            // setIsLoading(false);
            apiData = res?.data?.data?.map((item, i) => ({
                A: `${item?.A}`,
                B: `${item?.B}`,
                C: `${item?.C}`,
                D: `${item?.D}`,
                results: item?.surveyAttempt?.[0]?.results?.map(result => {
                    return (

                        {
                            answer: result?.answer,
                            percentage: result?.percentage
                        }
                    )
                }
                    // answer : result?.answer,
                    // percentage : result?.percentage

                ),
                value: item?._id,
            }));
        } else {
            // setIsLoading(false);
        }
        setOptions(apiData);
    }


    // const q = `${DepartmentDropdown?.[0]?.value}`
    // console.log("mmmmm", q)

    //for submit functionality
    const submitHandler = async () => {
        // if (DepartmentDropdown?.[0]?.value) {
        //     toast?.error("Question is required")
        // }
        // if (answerGiven === "") {
        //     toast?.error("Answer is required")
        // }
        if (userEmail === "") {
            toast?.error("User Email is required")
        }

        let data = {};
        data = {
            "userEmail": userEmail,
            "responses": Object.keys(surveyans)?.map(questionId => ({
                "questionId": questionId,
                "answerGiven": surveyans[questionId]
            }))
           
        }


        // data = {
        //     questionId: `${DepartmentDropdown?.[0]?.value}`,
        //     answerGiven: answerGiven,
        //     userEmail: userEmail,
        // };
        // console.log("quizasdf", data);
        // return false;
        const res = await ApiHelperFunction({
            urlPath: "/take-login-survey",
            method: "POST",
            data,
        });
        if (res?.status) {
            toast.success(res?.data?.message);
            closemodal();
        } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
            // console.log("ERROR CREATING USER3", res);
        }
    };

    // claculateAnsPercentage
    // const claculateAnsPercentage = (key, result) => {
    //     return result?.find(ele => ele.answer === key)?.percentage?.toFixed(2)
    // }



    useEffect(() => {
        // dispatch(getLoginSurvey())
        getLoginsurveyData();
    }, [])

    useEffect(() => {
        getManagerData()
    }, [DepartmentDropdown?.[0]?.value]);
    // console.log("DepartmentDropdown",DepartmentDropdown)

    return (
        <>
            <section className='quizesechmaoinbgj'>
                <div className='quizesechmaoinbgjbngujmain'>
                    <div className='logoandcrtgsjkidgftop'>
                        <div className="emplyedivlogoimgmain">
                            <img src={employeelandinglogo} lat="..." />
                        </div>
                        <div className='crtsiuoicoimlandikb' onClick={() => closemodal()}>
                            <i className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    {/* <div className='quizesectionbtm'>
                        <div className='quizesectionbtmandk'>


                            <div className='qizesectqstndiv'>
                                <p className='qizesectqstnrtxupp'>{DepartmentDropdown?.[0]?.label}</p>
                            </div>



                            {
                                options?.map((item, i) => {
                                    // console.log("itemvb", item)
                                    return (
                                        <>
                                            <div
                                                key={i}
                                                // className="bgcoloranswers"
                                                className={answerGiven === "A" ? "btnscactbamsvd" : "bgcoloranswers"}
                                                onClick={() => handlequwstionclick("A")} style={{ border: `1px solid ${questioncolors ? 'green' : '#ccc'}`, }}>
                                                <div className='bganswersoptnanseflx'>
                                                    <p className='amlsmpptycgp'>A:</p>
                                                    <p className='amlsmpptycgp'>{item?.A}</p>
                                                </div>
                                                {
                                                    per && (<p className='amlsmpptycgp'>{claculateAnsPercentage("A", item?.results)} %</p>)
                                                }


                                            </div>
                                            <div
                                                //  className="bgcoloranswers" 
                                                className={answerGiven === "B" ? "btnscactbamsvd" : "bgcoloranswers"}
                                                onClick={() => handlequwstionclick("B")} style={{ border: `1px solid ${questioncolors ? 'green' : '#ccc'}`, }}>
                                                <div className='bganswersoptnanseflx'>
                                                    <p className='amlsmpptycgp'>B:</p>
                                                    <p className='amlsmpptycgp'>{item?.B}</p>
                                                </div>
                                                {
                                                    per && (<p className='amlsmpptycgp'>{claculateAnsPercentage("B", item?.results)} %</p>)
                                                }
                                            </div>
                                            <div
                                                // className="bgcoloranswers"btnscactbamsvd
                                                className={answerGiven === "C" ? "btnscactbamsvd" : "bgcoloranswers"}
                                                onClick={() => handlequwstionclick("C")} style={{ border: `1px solid ${questioncolors ? 'green' : '#ccc'}`, }}>
                                                <div className='bganswersoptnanseflx'>
                                                    <p className='amlsmpptycgp'>C:</p>
                                                    <p className='amlsmpptycgp'>{item?.C}</p>
                                                </div>
                                                {
                                                    per && (<p className='amlsmpptycgp'>{claculateAnsPercentage("C", item?.results)} %</p>)
                                                }
                                            </div>
                                            <div
                                                // className="bgcoloranswers"
                                                className={answerGiven === "D" ? "btnscactbamsvd" : "bgcoloranswers"}
                                                onClick={() => handlequwstionclick("D")} style={{ border: `1px solid ${questioncolors ? 'green' : '#ccc'}`, }}>
                                                <div className='bganswersoptnanseflx'>
                                                    <p className='amlsmpptycgp'>D:</p>
                                                    <p className='amlsmpptycgp'>{item?.D}</p>
                                                </div>
                                                {
                                                    per && (<p className='amlsmpptycgp'>{claculateAnsPercentage("D", item?.results)} %</p>)
                                                }
                                            </div>
                                        </>
                                    )
                                })
                            }
                           
                        </div>

                        <div className='txtcrptyjb'>
                            <p className='txtcrptyjbpp'>Your Coprporate Email</p>
                            <input
                                type='email'
                                name='userEmail'
                                value={userEmail}
                                onChange={(e) => setuserEmail(e.target.value)}
                            />
                        </div>
                        <button
                            type='button'
                            className='sbmyjhvbhvbtynm'
                            onClick={submitHandler}

                        >Submit</button>
                    </div> */}
                    <div className='quizequewstiondiv'>
                        <ul className='quizequewstiondivul'>
                            {
                                surveyData?.map((item) => {
                                    return (
                                        <div className=''>
                                            <li className='quizequewstiondivli'>
                                                <p className='quizequewstiondivulppp'>{item?.surveyQuestion}</p>
                                                <div className=''>
                                                    <div>
                                                        <div className='quizeqanschedkbox'>
                                                            <input
                                                                type="checkbox"
                                                                id=""
                                                                value="A"
                                                                checked={surveyans[item?._id] === "A"}
                                                                onChange={(e) => handleChangeAns(item?._id, e.target.value)}
                                                            />
                                                            <label htmlFor="">{item?.A}</label>
                                                        </div>
                                                        <div className='quizeqanschedkbox'>
                                                            <input
                                                                type="checkbox"
                                                                id=""
                                                                value="B"
                                                                checked={surveyans[item?._id] === "B"}
                                                                onChange={(e) => handleChangeAns(item?._id, e.target.value)} />
                                                            <label htmlFor="">{item?.B}</label>
                                                        </div>
                                                        {item?.C && <div className='quizeqanschedkbox'>
                                                            <input type="checkbox" id=""
                                                                value="C"
                                                                checked={surveyans[item?._id] === "C"}
                                                                onChange={(e) => handleChangeAns(item?._id, e.target.value)}
                                                            />
                                                            <label htmlFor="">{item?.C}</label>
                                                        </div>}
                                                        {item?.D && <div className='quizeqanschedkbox'>
                                                            <input type="checkbox" id=""
                                                                value="D"
                                                                checked={surveyans[item?._id] === "D"}
                                                                onChange={(e) => handleChangeAns(item?._id, e.target.value)}
                                                            />
                                                            <label htmlFor="">{item?.D}</label>
                                                        </div>}
                                                    </div>

                                                </div>
                                            </li>
                                        </div>
                                    )
                                })
                            }


                            {/* <div className=''>
                                <li className='quizequewstiondivli'>
                                    <p className='quizequewstiondivulppp'>What is Lorem Ipsum</p>
                                    <div className=''>
                                        <div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a bike</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="vehicle2" defaultValue="" />
                                                <label htmlFor=""> I have a car</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a boat</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a boat</label>
                                            </div>
                                        </div>

                                    </div>
                                </li>
                            </div>


                            <div className=''>
                                <li className='quizequewstiondivli'>
                                    <p className='quizequewstiondivulppp'>What is Lorem Ipsum</p>
                                    <div className=''>
                                        <div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a bike</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="vehicle2" defaultValue="" />
                                                <label htmlFor=""> I have a car</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a boat</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a boat</label>
                                            </div>
                                        </div>

                                    </div>
                                </li>
                            </div>


                            <div className=''>
                                <li className='quizequewstiondivli'>
                                    <p className='quizequewstiondivulppp'>What is Lorem Ipsum</p>
                                    <div className=''>
                                        <div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a bike</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="vehicle2" defaultValue="" />
                                                <label htmlFor=""> I have a car</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a boat</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a boat</label>
                                            </div>
                                        </div>

                                    </div>
                                </li>
                            </div>


                            <div className=''>
                                <li className='quizequewstiondivli'>
                                    <p className='quizequewstiondivulppp'>What is Lorem Ipsum</p>
                                    <div className=''>
                                        <div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a bike</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="vehicle2" defaultValue="" />
                                                <label htmlFor=""> I have a car</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a boat</label>
                                            </div>
                                            <div className='quizeqanschedkbox'>
                                                <input type="checkbox" id="" name="" defaultValue="" />
                                                <label htmlFor=""> I have a boat</label>
                                            </div>
                                        </div>

                                    </div>
                                </li>
                            </div> */}


                            <div className='txtcrptyjbdvlanndinginbpiouy'>
                                <p className='txtcrptyjbppsidvlanndingh'>Your Coprporate Email</p>
                                <input
                                    type='email'
                                    name='userEmail'
                                    value={userEmail}
                                    onChange={(e) => setuserEmail(e.target.value)}
                                />
                            </div>

                            <button
                                type='button'
                                className='sbmyjhvbhvbtynm'
                                onClick={submitHandler}

                            >Submit</button>

                        </ul>
                    </div>
                </div>
            </section >
        </>
    )
}

export default QuizeQuestionsmodal