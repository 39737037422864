import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { registerCompany } from '../../redux/slices/registrationSlice';

const Subscriptionplan = () => {
    const navigate = useNavigate();
    const [clickedButton, setClickedButton] = useState(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const formData = location?.state
    // console.log("ponm",clickedButton)

    
    const handleButtonClick = (buttonName) => {
        setClickedButton(buttonName);
    };


    //Submit functionality
    const handleSubmit = () => {
        dispatch(registerCompany(formData));
        navigate('/')
    }
    return (
        <>
            <section className='subscriptionsectionmain'>
                <div className="subscriptiondivmain">
                    <div className=''>
                        <h4 className='chsesubdcdhppaln'>Choose Your Subscription Plan</h4>
                        <p className='chsesubdcdhppalntxtp'>Lorem ipsum dolor sit amet.
                        </p>
                    </div>
                    <div className='subscriptionmaindibflx'>
                        <div className='subscriptionmaindibfrst'>
                            <div className='subscriptionmaindibfrstbrdr'>
                                <div className='sbsrptroinnbscd'>
                                    <p className='basctxtpjkp'>Basic</p>
                                    <p className='basctxtpjkpknk'>Starter</p>
                                </div>

                                <div className="sbsrptroinnbscdanb">
                                    <p className='priceoplantxtpp'>
                                        <sup className='priceoplantxtppdlr'>
                                            $
                                        </sup>
                                        60
                                        <sub className='priceoplantxtppdlrtcfyhy'>
                                            /month
                                        </sub>
                                    </p>
                                </div>

                                <div className='sbsrptroinnbscdanbmk'>

                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-check"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>

                                    <p className='listdivallmnfst' >
                                        <span>
                                            <i class="fa-solid fa-check"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>

                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-check"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>
                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-xmark"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>
                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-xmark"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>

                                </div>
                                <div className='chskjbhpalnndiv'>
                                    {/* <button className='chskjbhpalnn'>
                                        <p className='chskjbhpalnnttx'>Buy Your Plan</p>
                                    </button> */}
                                    <button
                                        className={`chskjbhpalnn ${clickedButton === 'plan1' ? 'activeplkl' : 'chskjbhpalnnttx'}`}
                                        onClick={() => handleButtonClick('plan1')}
                                    >
                                        <p className={clickedButton ? "chskjbhpalnnttxavtvbm" : "chskjbhpalnnttx"}>Buy Your Plan</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='subscriptionmaindibfrst'>
                            <div className='subscriptionmaindibfrstbrdr'>
                                <div className='sbsrptroinnbscd'>
                                    <p className='basctxtpjkp'>Standard</p>
                                    <p className='basctxtpjkpknk'>Better</p>
                                </div>

                                <div className="sbsrptroinnbscdanb">
                                    <p className='priceoplantxtpp'>
                                        <sup className='priceoplantxtppdlr'>
                                            $
                                        </sup>
                                        70
                                        <sub className='priceoplantxtppdlrtcfyhy'>
                                            /month
                                        </sub>
                                    </p>
                                </div>

                                <div className='sbsrptroinnbscdanbmk'>

                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-check"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>

                                    <p className='listdivallmnfst' >
                                        <span>
                                            <i class="fa-solid fa-check"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>

                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-check"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>
                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-xmark"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>
                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-xmark"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>

                                </div>
                                <div className='chskjbhpalnndiv'>
                                    {/* <button className='chskjbhpalnn'>
                                        <p className='chskjbhpalnnttx'>Buy Your Plan</p>
                                    </button> */}
                                    <button
                                        className={`chskjbhpalnn ${clickedButton === 'plan2' ? 'activeplkl' : 'chskjbhpalnnttx'}`}
                                        onClick={() => handleButtonClick('plan2')}
                                    >
                                        <p className={clickedButton ? "chskjbhpalnnttxavtvbm" : "chskjbhpalnnttx"}>Buy Your Plan</p>
                                    </button>
                                </div>


                            </div>


                        </div>
                        <div className='subscriptionmaindibfrst'>
                            <div className='subscriptionmaindibfrstbrdr'>
                                <div className='sbsrptroinnbscd'>
                                    <p className='basctxtpjkp'>Premium</p>
                                    <p className='basctxtpjkpknk'>All in One</p>
                                </div>

                                <div className="sbsrptroinnbscdanb">
                                    <p className='priceoplantxtpp'>
                                        <sup className='priceoplantxtppdlr'>
                                            $
                                        </sup>
                                        90
                                        <sub className='priceoplantxtppdlrtcfyhy'>
                                            /month
                                        </sub>
                                    </p>
                                </div>
                                <div className='sbsrptroinnbscdanbmk'>

                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-check"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>

                                    <p className='listdivallmnfst' >
                                        <span>
                                            <i class="fa-solid fa-check"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>

                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-check"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>
                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-xmark"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>
                                    <p className='listdivallmnfst'>
                                        <span>
                                            <i class="fa-solid fa-xmark"></i>
                                        </span>
                                        Lorem ipsum dolor sit amet.
                                    </p>

                                </div>
                                <div className='chskjbhpalnndiv'>
                                    {/* <button className='chskjbhpalnn'>
                                        <p className='chskjbhpalnnttx'>Buy Your Plan</p>
                                    </button> */}
                                    <button
                                        className={`chskjbhpalnn ${clickedButton === 'plan3' ? 'activeplkl' : 'chskjbhpalnnttx'}`}
                                        onClick={() => handleButtonClick('plan3')}
                                    >
                                        <p className={clickedButton ? "chskjbhpalnnttxavtvbm" : "chskjbhpalnnttx"}>Buy Your Plan</p>
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className='sbmtbtndivklbtn'>
                        <button
                            type='button'
                            className='sbmtbtndivkl'
                            onClick={() => handleSubmit()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Subscriptionplan
