import { BrowserRouter,Routes,Route } from 'react-router-dom';
import './App.css';
import Registrationpage from '../src/Athentication/Registrationpage';
import Subscriptionplan from '../src/View/Landingpage/Subscriptionplan';
import EliteExperienceHub from '../src/View/Landingpage/EliteExperienceHub';
import EliteWorkplaceCertification from '../src/View/Landingpage/EliteWorkplaceCertification';
import Landingpage from '../src/View/Landingpage/Landingpage';
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
      <Route path="/registrationpage" element={<Registrationpage />} />
      <Route path="/subscriptionplan" element={<Subscriptionplan />} />
      <Route path="" element={<Landingpage />} />
      <Route path="/EliteExperienceHub" element={<EliteExperienceHub />} />
      <Route path="/EliteWorkplaceCertification" element={<EliteWorkplaceCertification />} />
      </Routes>
      </BrowserRouter>
      <Toaster />
    </div>
  );
}

export default App;
